import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";
import useTimeout from "../../hooks/useTimeout";
import { getThemeForRequest, resolveResourcePath } from "../../whitelabel/themeManager";

const pulse = keyframes`
 0% { transform: scale(1); }
 30% { transform: scale(1.1); }
 100% { transform: scale(1); }
`;
const AppLoaderContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
`;

const AppLoaderImage = styled.img.attrs({
    src: resolveResourcePath(`/img/${getThemeForRequest().logoPrefix}-logo-120.png`),
    alt: "loader",
    width: "80px",
    className: "loading-logo-pulsating"
})`
    transform: scale(1);
    animation: ${pulse} 1.4s infinite;
    animation-timing-function: ease-in;
`;

/**
 * Loader component when app is in init=false status
 * @returns
 */
const InitLoader = () => {
    const [timedOut, set_timedOut] = useState(false);
    const { t } = useTranslation();

    useTimeout("initLoader", () => set_timedOut(true), 30000, true);

    return (
        <AppLoaderContainer>
            <AppLoaderImage />
            <p>{t("common.loading")}</p>
            {timedOut && (
                <p>
                    <br />
                    <br />
                    {t("common.module_loader_fallback")}
                </p>
            )}
        </AppLoaderContainer>
    );
};

export default InitLoader;
