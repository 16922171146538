import { createContext, useContext } from "react";
import { FavoriteEntry } from "../components/lists/FavoritesList";
import uuid from "../utils/uuid";
import { VideoCallProvider } from "./videoCalls";

export interface PremiumBooster {
    hasPremiumBooster: boolean;
    dateActivated: Date;
    duration: number;
    inventory?: number;
    remaining?: number;
}

export const globalContextDefault = {
    uhidB: null,
    vtk: null,
    logged: null,
    frameOn: null,
    sexe: null,
    showCam: false,
    camViewOnly: false,
    camBlurEnabled: false,
    alreadyConnected: false,
    forcedFrame: null,
    sideMenuOpen: false,
    mediaDeviceAllowed: false,
    specialProcess: null,
    adultConfirmed: false,
    notifications: [],
    firstConnection: false,
    langTranslation: "en",

    // Males observable
    minutesPacks: null,
    seconds: 0,
    purchasesNumber: 0,
    socialAuthProviders: [],
    userSuspended: false,

    // Females observable
    lpFrom: null,
    points: 0,
    pointsTotal: 0,
    stepFemaleRegistration: 1,
    modelRegistration: {
        pseudo: "",
        email: "",
        country: "",
        emailVerification: "",
        proofId: "",
        proofPerson: "",
        phoneNumber: "",
        password: "",
        fullName: "",
        dateOfBirth: "",
        availability: { workingDays: [], workingHours: null },
    },
    modelRegistrationShowIntro: false,
    spicyButtonExplained: false,

    tabid: uuid(),
    appRef: null,
    directCallPartner: null as FavoriteEntry,
    twoFactorEnabled: false,
    videoCallProvider: "opentok" as VideoCallProvider,
};

type INotification = {
    message: string;
    closable: boolean;
};

export type GlobalContextI = typeof globalContextDefault & {
    setValues: (values: GlobalStateFragment) => void;
    changeFrameOn: (frame: string) => void;
    setShowCam: (active: boolean) => void;
    setCamViewOnly: (viewOnly: boolean) => void;
    useFunction: (...v: any[]) => void;
    pushNotification: (notification: INotification) => void;
    changePoints: (p: number, pa: number, force?: boolean) => void;
    setAppRef: (value: any) => void;
    setFirstConnection: (value: any) => void;
    setMediaDeviceAllowed: (value: boolean) => void;
    setAdultConfirmed: (value: any) => void;
    setSideMenuOpen: (value: any) => void;
    setSeconds: (value: number) => void;
    setCamBlur: (value: boolean) => void;
    setDirectCallPartner: (value: FavoriteEntry) => void;
};

// Typing of global state data
export type GlobalStateFragment = Omit<Partial<GlobalContextI>, "setValues">;

export const GlobalContext = createContext<GlobalContextI>(globalContextDefault as GlobalContextI);

export function useGlobal() {
    return useContext(GlobalContext);
}
