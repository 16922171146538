import { create } from "zustand";

export interface TimerStore {
    callHeartbeatInterval?: number;
    femalePointsWatchdogInterval?: number;
    useTimeoutStates?: Record<string, any>;

    setState: (newState) => void;
}

export const useTimerStore = create<TimerStore>((set) => ({
    callHeartbeatInterval: null,
    femalePointsWatchdogInterval: null,
    useTimeoutStates: {},

    setState: (newState: TimerStore) => {
        set({ ...newState });
    },
}));
