import React from "react";
import warnSvg from "../../svg/warn.svg";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Parent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 40px;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999999;
    overflow: hidden;

    .logo {
        width: 100px;
        height: 100px;
        margin-bottom: 20px;
    }
`;

const BanNotice = styled.p`
    font-size: 16px;
    color: #7c7c7c;
    text-align: center;
    max-width: 600px;
`;

export const UserSuspended = () => {
    const { t } = useTranslation();
    return (
        <Parent>
            <img src={warnSvg} className="logo" alt="Suspended" />
            <div>
                <BanNotice>{t("mobile.banned_message")}</BanNotice>
            </div>
        </Parent>
    );
};
