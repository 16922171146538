import { useEffect, useState } from "react";

export function useMediaQuery(query: string) {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);

    // cDM, cDU
    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => setMatches(media.matches);

        if (typeof media.addEventListener === "function") {
            media.addEventListener("change", listener);
        } else {
            // older browsers compatibility
            media.addListener(listener);
        }
        return () => {
            if (typeof media.removeEventListener === "function") media.removeEventListener("change", listener);
            else {
                // older browsers compatibility
                media.removeListener(listener);
            }
        };
    }, [query]);

    return matches;
}

export function useIsMobile() {
    return useMediaQuery("(max-width: 701px)");
}
