import { create } from "zustand";

export interface AvatarStore {
    avatarImageUrl?: string;
    isTakingPicture?: boolean;
    isTakingPictureManually?: boolean; // used to show default 'create new profile picture' UI state
    shouldSaveAvatar?: boolean;
    isSavingAvatar?: boolean;
    shouldRequestToCreateAvatarImage?: boolean;

    setState: (newState) => void;
    setAvatarImageUrl: (value: string) => void;
    setIsTakingPicture: (value: boolean) => void;
    setIsTakingPictureManually: (value: boolean) => void;
    setShouldSaveAvatar: (value: boolean) => void;
    setIsSavingAvatar: (value: boolean) => void;
    setShouldRequestToCreateAvatarImage: (value: boolean) => void;
}

export const useAvatarStore = create<AvatarStore>((set) => ({
    avatarImageUrl: null,
    isTakingPicture: false,
    isTakingPictureManually: true,
    shouldSaveAvatar: false,
    isSavingAvatar: false,
    shouldRequestToCreateAvatarImage: false,

    setState: (newState) => {
        set({ ...newState });
    },

    setAvatarImageUrl: (value: string) => {
        set({ avatarImageUrl: value });
    },

    setIsTakingPicture: (value: boolean) => {
        set({ isTakingPicture: value });
    },

    setIsTakingPictureManually: (value: boolean) => {
        set({ isTakingPictureManually: value });
    },

    setShouldSaveAvatar: (value: boolean) => {
        set({ shouldSaveAvatar: value });
    },

    setIsSavingAvatar: (value: boolean) => {
        set({ isSavingAvatar: value });
    },

    setShouldRequestToCreateAvatarImage: (value: boolean) => {
        set({ shouldRequestToCreateAvatarImage: value });
    },
}));
