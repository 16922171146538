import { create } from "zustand";

export interface PremiumBoosterStore {
    hasPremiumBooster?: boolean;
    dateActivated?: Date;
    inventory?: number;
    duration?: number;
    remaining?: number;
    isPaused?: boolean;
    durationRemaining?: number;
    timeSpentWithFree?: number;
    hideBoosterProgressBar?: boolean;
    pauseBoosterAutoActivate?: number;

    setState: (newState) => void;
    resetBoosterState: () => void;
}

export const usePremiumBoosterStore = create<PremiumBoosterStore>((set) => ({
    hasPremiumBooster: false,
    dateActivated: null,
    inventory: 0,
    duration: 0,
    remaining: 0,
    isPaused: false,
    durationRemaining: 0,
    timeSpentWithFree: 0,
    hideBoosterProgressBar: false,

    setState: (newState) => {
        set({ ...newState });
    },

    resetBoosterState: () => {
        set({ hasPremiumBooster: false, dateActivated: null, inventory: 0, duration: 0, remaining: 0 });
    },
}));
