const filters = [
    // /test/i
];

// (very) rough filter to omit sending known client side exceptions
export function sentryFilter(event, hint) {
    const error = hint.originalException;
    // TODO: include deeper inspection of exception objects
    if (error && typeof error === "string") {
        for (let i = 0; i < filters.length; i++) {
            const el = filters[i];
            if (error.match(el)) return null;
        }
    }
    try {
        if (hint.originalException.stack.indexOf('opentok-v2.22.4.min.js') >= 0 && Math.random() < 0.9)
            return null; // ~90% chance to dismiss opentok related errors
    } catch (e) {
        // console.log("Sentry filter error", e)
    }
    return event;
}
