import { setUser as SentrySetUser } from "@sentry/react";
import cookies from "browser-cookies";
import { useCallback, useEffect, useState } from "react";
import { setFemaleFeatureFlags } from "../contexts/featureFlags";
import appVersion from "../utils/appVersion";
import { computeFingerprint, handlegclid } from "../utils/fingerprint";
import useUserTracking from "./useUserTracking";
import axios from "./../services/axios";
import { usePublicConfigStore } from "../stores/publicConfigStore";
import AvatarFallback from "../png/avatar-fallback.svg";
import { usePremiumBoosterStore } from "../stores/premiumBoosterStore";
import { useAvatarStore } from "../stores/AvatarStore";
import { useFavoritesStore } from "../stores/favoritesStore";
import { getSeoURLPath } from "../utils/seo";
import { useVariantExperiments } from "./useVariantExperiments";
import { LuckyWheel } from "../components/lucky-wheel/data";
import { useLuckyWheelStore } from "../stores/luckyWheelStore";
import { useAuthStore } from "../stores/authStore";
import { useExperimentStore } from "../stores/experimentStore";

type InitData = Readonly<{
    vtk?: string;
    sexe?: string;
    ctk: string;
    hasFingerprint: boolean;
    logged: boolean;
    points?: number;
    seconds?: number;
    firstConnection?: boolean;
    uhidB?: string;
    femaleFeatureFlags?: any;
    pointsTotal?: number;
    avatarImageUrl?: string;
    langTranslation?: string;
    publicConfig?: any;
    twoFactorEnabled?: boolean;
    purchasesNumber?: number;
    provider: string;
    timeSpentWithFree: number;
    premiumBooster?: any;
    hasFavorites: boolean;
    variantExperiments?: { [key: string]: number };
    luckyWheel: LuckyWheel;
    luckyWheelTotalTimeSpent: number;
    promptAppleIdDiscontinued: boolean;
    requirePasswordChange: boolean;
    fingerprintExperiments?: { [key: string]: number };
}>;

/**
 * Identity the session via api/buurn/public/init to create an uhidB.
 * Do not use useModal because <ModalProvider is not rendered.
 */
const useIdentity = (setValues: any) => {
    const [isInit, setIsInit] = useState(false);
    const { identifyUser, startTracking } = useUserTracking();
    const { setState: setPremiumBooster } = usePremiumBoosterStore();
    const { setAvatarImageUrl } = useAvatarStore();
    const { setHasFavorites } = useFavoritesStore();
    const { setVariantExperiments } = useVariantExperiments();
    const { setLuckyWheel, setTotalTimeSpent } = useLuckyWheelStore();
    const { setPromptAppleIdDiscontinued } = useAuthStore();

    const onInit = useCallback(
        async (initData: InitData) => {
            if (initData.ctk) window["ctk"] = initData.ctk;

            await handlegclid(initData);

            const changes: any = {
                langTranslation: initData.langTranslation,
            };

            if (!!initData.sexe) {
                // It's female registered of visitor who selected female sex
                if (initData.sexe === "female") {
                    changes.points = initData.points;
                    changes.pointsTotal = initData.pointsTotal;
                    setFemaleFeatureFlags(initData.femaleFeatureFlags);
                    if (!!initData.logged) {
                        if (!!initData.firstConnection) {
                            changes.frameOn = "Home";
                            changes.firstConnection = true;
                        } else {
                            changes.frameOn = "Home";
                        }
                    } else {
                        const isFromLp = cookies.get("from_f_landing");
                        // The female comes from a specific link(female landing) which contains
                        // This cookie is created in the following repo
                        // See repo luckcrush-static-server/modules/routers/middlewares/checkQuery.js
                        if (!!isFromLp) {
                            changes.modelRegistrationShowIntro = false;
                            changes.frameOn = "Home";
                        } else {
                            changes.frameOn = "Home";
                        }
                    }
                } else {
                    // It's a male registered user or visitor who selected male sex
                    if (!!initData.logged) {
                        changes.seconds = initData.seconds;
                    }
                    changes.frameOn = "Home";

                    setHasFavorites(initData.hasFavorites || false);

                    if (!!initData.luckyWheel) {
                        setLuckyWheel(initData.luckyWheel);
                        setTotalTimeSpent(initData.luckyWheelTotalTimeSpent);
                    }

                    setPromptAppleIdDiscontinued(initData.promptAppleIdDiscontinued);
                }
                changes.vtk = initData.vtk;
                changes.uhidB = initData.uhidB;
                changes.sexe = initData.sexe;
                changes.twoFactorEnabled = initData.twoFactorEnabled;
                changes.purchasesNumber = initData.purchasesNumber;

                setAvatarImageUrl(initData.avatarImageUrl || AvatarFallback);

                setPremiumBooster({ ...(initData.premiumBooster || {}), timeSpentWithFree: initData.timeSpentWithFree });
            }
            const params = new URLSearchParams(window.location.search);
            if (params.has("modal")) {
                changes.forcedFrame = params.get("modal");
                console.info("Set forcedFrame to:", changes.forcedFrame);
            }

            if (initData.provider) {
                changes.videoCallProvider = initData.provider;
            }

            if (initData.variantExperiments) {
                setVariantExperiments(initData.variantExperiments);
            }

            if (initData.fingerprintExperiments) {
                useExperimentStore.setState(initData.fingerprintExperiments);
            }

            changes.logged = initData.logged;

            SentrySetUser({ id: initData.uhidB });

            usePublicConfigStore.setState({ ...(initData.publicConfig || {}) });

            // This is where the user start to see the application
            setValues(changes);
            setIsInit(true);
        },
        [setIsInit, setValues]
    );

    const init = useCallback(async () => {
        // Call init endpoint to determine nature of the user(new visitor, male, female, registered)
        const params = new URLSearchParams(window.location.search);
        params.set("t", Date.now() + "");
        params.set("v", appVersion);

        const fpRequired = params.has("hid") || params.has("aid") || !!cookies.get("from_f_landing") || cookies.get("sexe") === "female";
        const fpData = await computeFingerprint(!fpRequired);
        if (fpData) {
            params.set("fp", fpData.fp);
            params.set("fpscore", fpData.confidence_score + "");
        }

        const seoPage = getSeoURLPath();
        if (seoPage) {
            params.set("seoPage", seoPage);
        }

        const { data, status } = await axios.get<InitData>(`/buurn/public/init`, {
            withCredentials: true,
            params,
            validateStatus: (code) => code === 200 || code === 201 || code === 403,
        });

        if (status === 403) {
            setValues({ userSuspended: true });
            return;
        }
        if (data) {
            await onInit(data);
        }
    }, [onInit]);

    useEffect(() => {
        init();
    }, [init]);

    useEffect(() => {
        startTracking();
        identifyUser();
    }, [startTracking, identifyUser]);

    return { isInit };
};

export default useIdentity;
