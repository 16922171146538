import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ErrorBoundary from "./components/errors/ErrorBoundary";
import { GlobalProvider } from "./contexts/globalProvider";
import "./polyfills";
import "./themes/main.css";
import useWhiteLabelThemeProvider from "./whitelabel/useWhiteLabelThemeProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getThemeForRequest, googleSigninEnabled } from "./whitelabel/themeManager";

const GoogleOAuthConditionalProvider = ({ children }) => {
    if (googleSigninEnabled()) {
        const { socialLogin } = getThemeForRequest();
        const clientId = socialLogin.google ? socialLogin.google.client_id : "";

        return <GoogleOAuthProvider clientId={clientId}>{children}</GoogleOAuthProvider>;
    } else {
        return <>{children}</>;
    }
};

const Main = () => {
    useWhiteLabelThemeProvider();

    return (
        <ErrorBoundary>
            <GoogleOAuthConditionalProvider>
                <GlobalProvider>
                    <App />
                </GlobalProvider>
            </GoogleOAuthConditionalProvider>
        </ErrorBoundary>
    );
};

const containerElement = document.getElementById("luckycrush_container");
if (containerElement) {
    ReactDOM.render(<Main />, containerElement);
} else {
    throw new Error("missing element #luckycrush_container");
}
