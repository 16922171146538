export const text = Object.freeze({
    fr: "Français",
    en: "English",
    es: "Español",
    it: "Italiano",
    de: "Deutsch",
    nl: "Dutch",
    pt: "Português",
    ja: "日本語",
    fi: "Suomi",
    no: "Norsk",
    sv: "Svenska",
    ko: "한국어",
    ru: "Русский",
    ro: "Română",
});

export const supportedLngs = process.env.REACT_APP_LANGS.split(",");
export const defaultLanguage = "en";
