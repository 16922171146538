let store: { [key: string]: number };

export const useVariantExperiments = () => {
    return {
        setVariantExperiments: (data: { [key: string]: number }) => {
            store = data;
        },
        getVariantExperiment: (key) => {
            if (!store) return 0;
            return store[key];
        },
    };
};
