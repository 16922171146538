import { create } from "zustand";
import { LuckyWheel } from "../components/lucky-wheel/data";

export interface LuckyWheelStore {
    luckyWheel: LuckyWheel;
    totalTimeSpent: number;
    isModalOpen: boolean;
    componentLabel: "Wheel" | "GetBonus" | "ShowProgress";

    setLuckyWheel: (luckyWheel: LuckyWheel) => void;
    setTotalTimeSpent: (totalTimeSpent: number) => void;
    setIsModalOpen: (isOpen: boolean) => void;
    setComponentLabel: (componentLabel: "Wheel" | "GetBonus" | "ShowProgress") => void;
}

export const useLuckyWheelStore = create<LuckyWheelStore>((set) => ({
    luckyWheel: null,
    totalTimeSpent: null,
    isModalOpen: false,
    componentLabel: "Wheel",

    setLuckyWheel: (luckyWheel) => set({ luckyWheel }),
    setTotalTimeSpent: (totalTimeSpent) => set({ totalTimeSpent }),
    setIsModalOpen: (isModalOpen) => set({ isModalOpen }),
    setComponentLabel: (componentLabel) => set({ componentLabel }),
}));
