import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { eventLogger } from "../../services/api/eventLogger";
import LogoBase from "../elements/Logo";
import { gtag } from "../../utils/gtag";

interface IProps {
    t: any;
}

interface IState {
    hasError: boolean;
}

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #121125;
    background-color: rgb(248, 248, 248);
    font-family: "proxima-nova";
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr auto auto auto auto 1fr;
    grid-gap: 12px;
    justify-items: center;
`;

const H2 = styled.h2`
    align-self: center;
    text-align: center;
    font-size: 28px;
    padding-left: 16px;
    padding-right: 16px;
    font-weight: 500;
`;

const Text = styled.div`
    align-self: center;
    text-align: center;
    font-size: 20px;
    padding-left: 16px;
    padding-right: 16px;
`;

const Logo = styled(LogoBase)`
    width: 49px;
    height: 50px;
    margin-top: 24px;
    filter: grayscale(100%);
    cursor: pointer;
`;

class ErrorBoundary extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error) {
        gtag("event", "page_view", {
            page_path: "/error-boundary/",
            error: error.toString(),
        });

        eventLogger({
            event: "error-boundary",
            error: error.toString(),
        });
    }

    render() {
        if (this.state.hasError) {
            return this.renderError();
        }

        return this.props.children;
    }

    renderError() {
        const { t } = this.props;
        return (
            <Container>
                <div></div>
                <H2
                    dangerouslySetInnerHTML={{
                        __html: t("common.error_boundary_title"),
                    }}
                ></H2>
                <Text
                    dangerouslySetInnerHTML={{
                        __html: t("common.error_boundary_text1"),
                    }}
                ></Text>
                <Text
                    dangerouslySetInnerHTML={{
                        __html: t("common.error_boundary_text2"),
                    }}
                ></Text>
                <Logo onClick={() => window.location.reload()} />
                <div></div>
            </Container>
        );
    }
}

export default withTranslation()(ErrorBoundary);
