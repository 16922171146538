import axios from "../../services/axios";
import appVersion from "../../utils/appVersion";
import { getAxiosHeaders } from "../../whitelabel/themeManager";

export const eventLogger = async (data) => {
    try {
        const { data: respData } = await axios.post(`/buurn/public/visitor/events`, { ...data, ui_version: appVersion }, { withCredentials: true, timeout: 10000, headers: getAxiosHeaders() });
        return respData;
    } catch (error) {
        console.error(error);
    }
};
