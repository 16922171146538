import { create } from "zustand";

export interface FavoritesStore {
    hasFavorites: boolean;
    shouldPreventFavoritesMatch: boolean;
    activeTab: "favorites" | "recommended";
    isExpanded: boolean;

    setHasFavorites: (value: boolean) => void;
    setShouldPreventFavoritesMatch: (value: boolean) => void;
    setActiveTab: (value: "favorites" | "recommended") => void;
    setIsExpanded: (value: boolean) => void;
}

export const useFavoritesStore = create<FavoritesStore>((set) => ({
    hasFavorites: false,
    shouldPreventFavoritesMatch: false,
    activeTab: "favorites",
    isExpanded: false,

    setHasFavorites: (value: boolean) => {
        set({ hasFavorites: value });
    },

    setShouldPreventFavoritesMatch: (value: boolean) => {
        set({ shouldPreventFavoritesMatch: value });
    },

    setActiveTab: (value: "favorites" | "recommended") => {
        set({ activeTab: value });
    },

    setIsExpanded: (value: boolean) => {
        set({ isExpanded: value });
    },
}));
