import { useCallback } from "react";
import { useGlobal } from "../contexts/global";

export default function useUserTracking() {
    const { uhidB, sexe: gender, logged } = useGlobal();

    const pauseTracking = useCallback(() => {
        try {
        } catch (error) {}
    }, []);

    const resumeTracking = useCallback(() => {
        try {
        } catch (error) {}
    }, []);

    const startTracking = useCallback(() => {
        try {
        } catch (error) {
            console.error("smartlook", error);
        }
    }, [gender, logged]);

    const identifyUser = useCallback(
        (email?: string) => {
            if (!!uhidB) {
                try {
                } catch (error) {}
            }
        },
        [uhidB]
    );

    // any generic event
    const logEvent = useCallback((eventName: string, props: Record<string, number | string | boolean>) => {
        try {
        } catch (error) {}
    }, []);

    // logs nagivation event
    const logNav = useCallback((path: string) => {
        try {
        } catch (error) {}
    }, []);

    return {
        startTracking,
        identifyUser,
        logEvent,
        logNav,
        pauseTracking,
        resumeTracking,
    };
}
