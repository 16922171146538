import localforage from "localforage";
import { isIframeEmbedded } from "../whitelabel/themeManager";

let localForageData = {};

// fallback in-memory driver for cases like chrome's incognito mode
const emptyLocalForageDriver: LocalForageDriver = {
    clear(_: (err: any) => void): Promise<void> {
        localForageData = {};
        return Promise.resolve(undefined);
    },
    getItem<T>(key: string, __: (err: any, value: T | null) => void): Promise<T | null> {
        return Promise.resolve(localForageData[key]);
    },
    iterate<T, U>(_: (value: T, key: string, iterationNumber: number) => U, __: (err: any, result: U) => void): Promise<U> {
        return Promise.resolve(undefined);
    },
    key(_: number, __: (err: any, key: string) => void): Promise<string> {
        return Promise.resolve("");
    },
    keys(_: (err: any, keys: string[]) => void): Promise<string[]> {
        return Promise.resolve(Object.keys(localForageData));
    },
    length(_: (err: any, numberOfKeys: number) => void): Promise<number> {
        return Promise.resolve(Object.keys(localForageData).length);
    },
    removeItem(key: string, __: (err: any) => void): Promise<void> {
        localForageData[key] = undefined;
        return Promise.resolve();
    },
    setItem<T>(key: string, value: T, ___: (err: any, value: T) => void): Promise<T> {
        localForageData[key] = value;
        return Promise.resolve(value);
    },
    _driver: "emptyLocalForageWrapper",
    _support: true,
    _initStorage: function (_: any) {},
};

localforage.config({
    name: "lc",
});

try {
    if (isIframeEmbedded()) {
        void localforage.defineDriver(emptyLocalForageDriver);
        void localforage.setDriver("emptyLocalForageWrapper");
        console.warn("localforage fallback active");
    }
} catch (e) {
    console.error(e);
}

localforage.ready().then((_) => {
    console.log("localforage: ", localforage.driver());
});

export { localforage as webStorage };
