import localforage from "localforage";
import React from "react";
import { UserOnlineStatus } from "../components/elements/Avatar";
import { FavoriteEntry } from "../components/lists/FavoritesList";
import { GlobalContext, PremiumBooster, globalContextDefault } from "./global";

export class GlobalProvider extends React.Component {
    state = { ...globalContextDefault };
    modifiers = ["setAdultConfirmed", "setCamViewOnly", "setCamBlur", "setShowCam", "changeFrameOn", "setAlreadyConnected", "setMediaDeviceAllowed", "setSideMenuOpen", "setUhidB", "setVtk", "changeSexe", "setLogged", "setSeconds", "setLpFrom", "setModelRegistrationShowIntro", "setSpecialProcess", "setForcedFrame", "setModelRegistration", "resetModelRegistration", "setStepFemaleRegistration", "changePoints", "setAppRef", "setFirstConnection", "setDirectCallPartner"];
    modifiersFunctions: any;

    constructor(props) {
        super(props);
        this.modifiersFunctions = this.modifiers.reduce((result, name) => {
            return { ...result, [name]: this[name] };
        }, {});
    }

    // UI actions
    setAdultConfirmed = (value) => this.setState({ adultConfirmed: value });
    setCamViewOnly = (value, cb) => this.setState({ camViewOnly: value }, cb);
    setCamBlur = (value, cb) => this.setState({ camBlurEnabled: value }, cb);

    setShowCam = (value) => {
        this.setState({ showCam: value });
    };
    changeFrameOn = (newFrame) => {
        this.setState({ frameOn: newFrame }, () => {
            console.log("§§§§ changeFrameOn", this.state.frameOn);
        });
    };
    setAlreadyConnected = (value) => {
        this.setState({ alreadyConnected: value });
    };
    setMediaDeviceAllowed = (value) => {
        this.setState({ mediaDeviceAllowed: value });
    };
    setSideMenuOpen = (value) => {
        this.setState({ sideMenuOpen: value });
    };

    // User auth actions
    setUhidB = (value) => {
        this.setState({ uhidB: value });
    };
    setVtk = (vtk) => {
        this.setState({ vtk });
    };
    changeSexe = (sexe) => {
        this.setState({ sexe });
    };
    setLogged = (value) => {
        this.setState({ logged: value });
    };

    setFirstConnection = (value) => {
        this.setState({ firstConnection: value });
    };
    // Males UI actions
    setSeconds = (value) => {
        this.setState({ seconds: value });
    };

    // Females UI actions
    setLpFrom = (value) => {
        this.setState({ lpFrom: value });
    };
    setModelRegistrationShowIntro = (value) => {
        this.setState({ modelRegistrationShowIntro: value });
    };
    setSpecialProcess = (value) => {
        this.setState({ specialProcess: value });
    };
    setForcedFrame = (value) => {
        this.setState({ forcedFrame: value });
    };

    setModelRegistration = (values) => {
        Object.keys(values).forEach((key) => {
            const { modelRegistration } = this.state;
            this.setState({
                modelRegistration: { ...modelRegistration, [key]: values[key] },
            });
        });
        localforage.setItem("modelRegistration", JSON.stringify(this.state.modelRegistration));
    };
    resetModelRegistration = () => {
        let cleanRegistration = Object.keys(this.state.modelRegistration).reduce((result, key) => {
            return { ...result, [key]: "" };
        }, {});
        this.setState({ modelRegistration: cleanRegistration });
    };
    setStepFemaleRegistration = (value, save = true) => {
        this.setState({ stepFemaleRegistration: value });
        if (!!save) {
            localforage.setItem("modelRegistrationStep", `${this.state.stepFemaleRegistration}`);
        }
    };
    changePoints = (points, addedPoints, force = false) => {
        this.setState((previous: any, _p: any) => {
            // ensure this is a strictly increasing function
            return {
                ...previous,
                points: previous.points <= points || force ? points : previous.points,
                pointsTotal: addedPoints ? previous.pointsTotal + addedPoints : previous.pointsTotal,
            };
        });
    };

    useFunction = (name: string, ...args) => {
        this[name](...args);
    };

    setValues = (values: any) => {
        this.setState({ ...values });
    };

    pushNotification = (notification) => {
        notification.id = Math.trunc(Math.random() * 10000000);
        this.setState({
            notifications: [...this.state.notifications, notification],
        });
    };

    setAppRef = (value: any) => {
        this.setState({ appRef: value });
    };

    setDirectCallPartner = (value: FavoriteEntry) => {
        this.setState({ directCallPartner: value });
    };

    render() {
        const { useFunction, setValues, pushNotification } = this;
        let value = {
            ...this.state,
            useFunction,
            setValues, // TODO remove use of setValues
            pushNotification,
            ...this.modifiersFunctions,
        };

        return <GlobalContext.Provider value={value}>{this.props.children}</GlobalContext.Provider>;
    }
}

export const GlobalHOC = (Children, fields?: string[]) => {
    return (props) => (
        <GlobalContext.Consumer>
            {(globalContext) => {
                let context: any = globalContext;
                if (!!fields && fields.length > 0) {
                    context = fields.reduce((result, v) => {
                        return { ...result, [v]: context[v] };
                    }, {});
                }

                return <Children {...props} globalContext={context} />;
            }}
        </GlobalContext.Consumer>
    );
};
