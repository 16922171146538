export interface SitePublicConfig {
    id: string;
    domain: string;
    displayName: string;
    apiBaseUrl: string;
    uiBaseUrl: string;
    svgIconPath: string;
    logoPrefix: string;
    emailContact: string;
    paymentProvider: string[];
    purchaseTermsKey: string;
    creditProcessorLogos: string;
    creditProcessorLogosMobile: string;
    trackConversions: boolean;
    affiliateEnabled: boolean;
    femaleLoginAllowed: boolean;
    subscribersSiteId: string;
    legalTosUrl: string;
    legalContactUrl: string;
    legalPrivacyUrl: string;
    turnstile: {
        siteKey: string;
    };
    socialLogin: {
        google?: {
            client_id: string;
        };
        apple?: {
            client_id: string;
        };
    };
    oneSignal: {
        appId: string;
        safari_web_id: string;
    };
    scssVars: {
        [key: string]: any;
    };
    extraCss: string;
    embeddedBuild: boolean;
}

const SiteConfig: SitePublicConfig = window["lc_siteConfig"];

const themeProxy = (obj: any) =>
    new Proxy(obj, {
        get(_, key) {
            return obj[key];
        },
    });

export const theme = themeProxy(SiteConfig.scssVars);

export function getSiteIdForRequest(): string {
    return SiteConfig.id;
}

export function getThemeForRequest(): SitePublicConfig {
    return SiteConfig;
}

export function isIframeEmbedded() {
    try {
        return window["lc_embedded"];
    } catch (e) {
        return false;
    }
}

// used e.g. in embedded applications where the account is managed externally sources
export function hideUserControls() {
    try {
        const query = new URLSearchParams(document.location.search);
        return window["lc_embedded"] && !query.get("restoreControls");
    } catch (e) {
        return false;
    }
}

// used e.g. in embedded applications where the account is managed externally sources
export function getAxiosHeaders() {
    const query = new URLSearchParams(document.location.search);
    const headers = {};
    if (/* window.frameElement !== null */ query.get("scope")) {
        headers["lc-iframe-origin"] = query.get("scope");
    }
    if (window["lc_c_origin"]) headers["lc-iframe-origin"] = window["lc_c_origin"];
    if (window["lc_c_client"]) headers["lc-client"] = window["lc_c_client"];
    if (window["lc_c_vtk"]) headers["lc-vtk"] = window["lc_c_vtk"];
    if (window["lc_c_utk"]) headers["lc-utk"] = window["lc_c_utk"];
    if (window["lc_c_uhidb"]) headers["lc-uhidb"] = window["lc_c_uhidb"];

    return headers;
}

/**
 * Environment-dependant: Provides relative resource path
 * @param path
 */
export function resolveResourcePath(path: string) {
    return process.env.NODE_ENV === "production" ? "/module" + path : path;
}

/**
 * Checks if Google Sign-In is enabled based on the presence of socialLogin configuration
 * and a valid Google client ID in the SiteConfig.
 * @returns {boolean} True if Google Sign-In is enabled, otherwise false.
 */
export function googleSigninEnabled(): boolean {
    return !!SiteConfig.socialLogin.google && !!SiteConfig.socialLogin.google.client_id;
}

/**
 * Checks if Sign in with Apple is enabled based on the presence of socialLogin configuration
 * and a valid Apple client ID in the SiteConfig.
 * @returns {boolean} True if Sign in with Apple is enabled, otherwise false.
 */
export function appleSigninEnabled(): boolean {
    return !!SiteConfig.socialLogin.apple?.client_id;
}
