import { useEffect, useMemo } from "react";
import { getThemeForRequest, resolveResourcePath } from "./themeManager";

/**
 * Provides custom themes per whitelabel
 */
export default function useWhiteLabelThemeProvider() {
    const stylePaths: string[] = useMemo(() => {
        const theme = getThemeForRequest();
        let paths = [];
        paths.push(resolveResourcePath(`/css/${theme.id}_main.css`));

        // workaround for the local dev version, because we didn't load css from the ejs templates
        if (!theme.embeddedBuild && theme.extraCss) {
            paths.push(resolveResourcePath(theme.extraCss));
        }

        return paths;
    }, []);

    useEffect(() => {
        let addedElements = [];
        const head = document.head;

        for (let i = 0; i < stylePaths.length; i++) {
            var stylePath = stylePaths[i];
            if (stylePath) {
                const link = document.createElement("link");

                link.type = "text/css";
                link.rel = "stylesheet";
                link.href = stylePath;

                head.append(link);
                addedElements.push(link);
            }
        }

        return () => {
            for (let i = 0; i < addedElements.length; i++) {
                head.removeChild(addedElements[i]);
            }
        };
    }, [stylePaths]);
}
