//
// I know it's dirty, but it works. We don't need bindings. Will refactor later.
//

export type FemaleFeatureFlags = {
    yotiKYCEnabled: boolean;
};

export const femaleFeatureFlags: FemaleFeatureFlags = {
    yotiKYCEnabled: false,
};

export function setFemaleFeatureFlags(val) {
    val = val || {};
    for (const key in val) {
        femaleFeatureFlags[key] = val[key];
    }
}
