export default `

font-family: 'proxima-nova';
font-size: 16px;
font-weight: 500;

.blue-button {
    background: #4eccf7;
    color: #fff;
    height: 55px;
    min-height: 55px;
    font-size: 17px;
    padding: 0px 40px;
    border-radius: 5px;
    min-width: 265px;
    text-align: center;
    border: none;
    cursor: pointer;
}

@media all and (max-width: 700px) {
   .full-modal {
        height: 100%;
        width: 100%;
        border-radius: 0px;
        margin: 0px;
    }

    .blue-button {
        min-width: 222px;
    }
}

.RSPBprogressBar {
    height: 18px;
    line-height: 1;
    border-radius: 50px;
    position: relative;
    background-color: #C5CBD3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
}

.RSPBprogressBar .RSPBprogression {
        position: absolute;
        transition: width 0.3s ease;
        left: 0;
        top: 0;
        bottom: 0;
        border-radius: 50px;
        background: rgb(248 10 108);
        z-index: -1;
    }
    
    
`;
