import { create } from "zustand";

interface AuthStore {
    promptAppleIdDiscontinued: boolean;

    setPromptAppleIdDiscontinued: (value: boolean) => void;
}

export const useAuthStore = create<AuthStore>((set) => ({
    promptAppleIdDiscontinued: false,

    setPromptAppleIdDiscontinued: (value: boolean) => {
        set({ promptAppleIdDiscontinued: value });
    },
}));
