import * as Sentry from "@sentry/react";
import React, { lazy, Suspense, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AppLoader from "./components/loaders/InitLoader";
import { useGlobal } from "./contexts/global";
import useDocumentHeight from "./hooks/useDocumentHeight";
import useIdentity from "./hooks/useIdentity";
import { useIsMobile } from "./hooks/useMediaQuery";
import "./i18n";
import appStyle from "./themes/app-style";
import appVersion from "./utils/appVersion";
import { sentryFilter } from "./utils/sentryFilter";
import { getSiteIdForRequest, theme } from "./whitelabel/themeManager";
import cookies from "browser-cookies";
import ClickOutside from "react-click-outside";
import { UserSuspended } from "./components/layouts/UserSuspended";

console.info("v:" + appVersion);

//sentry disabled in local dev mode
if (process.env.NODE_ENV !== "development") {
    Sentry.init({
        environment: process.env.NODE_ENV,
        dsn: "https://5cda66b082394ad291265eae305d4dc7@goldfish.luckycrush.live/5616589",
        beforeSend: sentryFilter,
        release: appVersion,
        sampleRate: 0.5,
        tracesSampleRate: 1,
        initialScope: {
            tags: { siteId: getSiteIdForRequest() },
        },
        ignoreErrors: ["ResizeObserver loop"],
    });
}
const Main = lazy(() => import("./Main"));
const Sexe = lazy(() => import("./components/other-frames/Sexe"));

const AppContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: ${theme.appContainerTextColor || "#121125"};
    //    box-shadow: 0 40px 80px rgba(0, 0, 0, 0.6);
    background: ${theme.appContainerBackground};
    ${appStyle}
`;

const App = () => {
    const { sexe, userSuspended, setAppRef, setValues } = useGlobal();
    const { isInit } = useIdentity(setValues);
    const appRef = useRef<HTMLDivElement>(null);
    const isMobile = useIsMobile();
    const height = useDocumentHeight();
    const [hasScrollFix, set_hasScrollFix] = useState(false);
    const [preventKeyboardScroll, setPreventKeyboardScroll] = useState(false);

    useEffect(() => {
        // extracted the same logic from useOnScreenKeyboardScrollFix
        const handleScroll = () => {
            const genderSelected = cookies.get("sexe") || cookies.get("utk") || cookies.get("ftk");
            if (hasScrollFix && isMobile && !!genderSelected) {
                window.scrollTo(0, 0);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [hasScrollFix, isMobile]);

    useEffect(() => {
        if (isMobile) {
            const bodyEl = document.getElementsByTagName("body")[0];
            const htmlEl = document.getElementsByTagName("html")[0];
            const containerEl = document.getElementById("luckycrush_container_top");
            if (!sexe) {
                if (containerEl) {
                    if (!containerEl.classList.contains("allow-mobile-scroll")) containerEl.classList.add("allow-mobile-scroll");
                }
                if (bodyEl) {
                    if (!bodyEl.classList.contains("allow-mobile-scroll")) bodyEl.classList.add("allow-mobile-scroll");
                }
                if (htmlEl) {
                    if (!htmlEl.classList.contains("allow-mobile-scroll")) htmlEl.classList.add("allow-mobile-scroll");
                }
            } else {
                if (containerEl) {
                    if (containerEl.classList.contains("allow-mobile-scroll")) containerEl.classList.remove("allow-mobile-scroll");
                }
                if (bodyEl) {
                    if (bodyEl.classList.contains("allow-mobile-scroll")) bodyEl.classList.remove("allow-mobile-scroll");
                }
                if (htmlEl) {
                    if (htmlEl.classList.contains("allow-mobile-scroll")) htmlEl.classList.remove("allow-mobile-scroll");
                }
            }
        }
    }, [isMobile, sexe]);

    useEffect(() => {
        if (!appRef.current) return;
        appRef.current.style.height = null; // reset height initially

        if (hasScrollFix && isMobile) {
            appRef.current.style.height = `${window.innerHeight - (window.innerHeight - height)}px`;
        } else if (!hasScrollFix) {
            appRef.current.style.height = null;
            if (!appRef.current.style.length) appRef.current.removeAttribute("style"); // remove empty style attribute
        }
    }, [height, hasScrollFix, isMobile]);

    useEffect(() => {
        setAppRef(appRef);
    }, [setAppRef]);

    const handleKeystrokes = useCallback(
        (e) => {
            if (preventKeyboardScroll && e.key === " " && !["INPUT", "TEXTAREA", "SELECT"].includes(e.target.tagName)) {
                e.preventDefault();
            }
        },
        [preventKeyboardScroll]
    );

    useEffect(() => {
        window.addEventListener("keydown", handleKeystrokes);
        return () => {
            window.removeEventListener("keydown", handleKeystrokes);
        };
    }, [handleKeystrokes]);

    const onAppFocus = useCallback(() => {
        if (!preventKeyboardScroll) setPreventKeyboardScroll(true);
    }, [preventKeyboardScroll]);

    const onAppBlur = useCallback(() => {
        if (preventKeyboardScroll) setPreventKeyboardScroll(false);
    }, [preventKeyboardScroll]);

    // @ts-ignore
    return (
        <ClickOutside onClickOutside={onAppBlur}>
            <AppContainer className={"app-container"} ref={appRef} onClickCapture={onAppFocus} onFocusCapture={onAppFocus}>
                {userSuspended ? <UserSuspended /> : <> {isInit ? <Suspense fallback={<AppLoader />}>{sexe ? <Main setHasScrollFixHandler={set_hasScrollFix} /> : <Sexe />}</Suspense> : <AppLoader />}</>}
            </AppContainer>
        </ClickOutside>
    );
};

export default App;
