import { create } from "zustand";

export interface ExperimentStore {
    welcome_frame: number;
    welcome_frame_v2: number;
    muf_enabled: number;
    quick_reply: number;
    recommended_models: number;

    setState: (newState) => void;
}

export const useExperimentStore = create<ExperimentStore>((set) => ({
    welcome_frame: null,
    welcome_frame_v2: null,
    muf_enabled: null,
    quick_reply: null,
    recommended_models: null,

    setState: (newState) => {
        set({ ...newState });
    },
}));
