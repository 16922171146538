import axios from "axios";
import MainPubSub from "../providers/MainPubSub";
import { getAxiosHeaders, getThemeForRequest } from "../whitelabel/themeManager";
import * as Sentry from "@sentry/react";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_HOST ? `${process.env.REACT_APP_API_HOST}/` : getThemeForRequest().apiBaseUrl,
    headers: getAxiosHeaders(),
    // circuit breaker for eventual infinite redirects
    maxRedirects: 4,
});

instance.interceptors.response.use(
    function (response) {
        // Do something with response data
        return response;
    },
    function (error: any) {
        // SPECIFIC case of http conflict errors must be handled by client
        if (error.response && error.response.status === 409) {
            return Promise.reject(error);
        }

        // reload page if user is unauthenticated
        if (error.response && error.response.status === 401) {
            MainPubSub.publish("open-modal", {
                unauthenticated: { visible: true },
            });
        } else {
            // showError
            // MainPubSub.publish("open-modal", {
            //    showError: { visible: true },
            // });
        }
        if (error.response && error.response.status < 401 && error.response.status > 404) {
            Sentry.captureException(error);
        }

        // rethrow
        return Promise.reject(error);
    }
);

export const isAxiosError = axios.isAxiosError;
export default instance;
