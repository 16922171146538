import { Observable, Observer, Subject } from "rxjs";

class MainPubSub {
    _observers = new Map<string, Observer<any>>();
    _observables = new Map<string, Observable<any>>();

    publish(listenerName: string, data: any = null) {
        const sub = this._observers.get(listenerName);
        if (sub) {
            sub.next(data);
        }
    }

    subscribe<T = any>(event: string, clbk: (data: T) => void, createObserver: boolean = true) {
        const observable = this._observables.get(event);
        if (!observable && createObserver) {
            const subject = new Subject<any>();
            this._observers.set(event, subject);
            this._observables.set(event, subject);
            return subject.subscribe(clbk);
        }
        if (observable) {
            return observable.subscribe(clbk);
        }
    }

    addObservable(event: string, obs: Observable<any>) {
        this._observables.set(event, obs);
    }

    removeObservable(event: string) {
        this._observables.delete(event);
    }

    cleanAllObservables() {
        this._observables.clear();
    }
}

export default new MainPubSub();
