import { useEffect, useRef } from "react";
import { useTimerStore } from "../stores/timerStore";

export default function useTimeout(id: string, onTimeout: () => void, timeoutMs: number, shouldTriggerTimeout: boolean) {
    const { useTimeoutStates, setState } = useTimerStore();
    const onTimeoutRef = useRef(onTimeout);

    useEffect(() => {
        onTimeoutRef.current = onTimeout;
    }, [onTimeout]);

    useEffect(() => {
        if (useTimeoutStates[id]) {
            clearTimeout(useTimeoutStates[id]);
            setState({
                [`useTimeoutStates.${id}`]: null,
            });
        }

        let timeoutRef;
        if (shouldTriggerTimeout) {
            console.info(`[useTimeout - ${id}] started`, timeoutMs);
            timeoutRef = setTimeout(() => {
                if (onTimeoutRef.current) {
                    console.info(`[useTimeout - ${id}] fired`, timeoutMs);
                    onTimeoutRef.current();
                }
            }, timeoutMs);

            setState({
                [`useTimeoutStates.${id}`]: timeoutRef,
            });
        }

        return () => {
            console.info(`[useTimeout - ${id}] cancelled`, timeoutMs);
            if (timeoutRef) clearTimeout(timeoutRef);
        };
    }, [timeoutMs, shouldTriggerTimeout]);
}
