/* eslint-disable no-lone-blocks */

import i18n, { InitOptions } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import I18NextHttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { supportedLngs } from "./langs";
import { getThemeForRequest } from "../whitelabel/themeManager";

const cacheBuster = {
    get t() {
        // REACT_APP_VERSION is unset in development mode.
        return process.env.REACT_APP_VERSION || Date.now() + "";
    },
};
// translation backen settings
const backend = {
    // path where resources get loaded from, or a function
    // returning a path:
    // function(lngs, namespaces) { return customPath; }
    // the returned path will interpolate lng, ns if provided like giving a static path
    //
    // If allowMultiLoading is false, lngs and namespaces will have only one element each,
    // If allowMultiLoading is true, lngs and namespaces can have multiple elements
    loadPath: process.env.REACT_APP_LOCALES_PATH,

    // your backend server supports multiloading
    // /locales/resources.json?lng=de+en&ns=ns1+ns2
    // Adapter is needed to enable MultiLoading https://github.com/i18next/i18next-multiload-backend-adapter
    // Returned JSON structure in this case is
    // {
    //  lang : {
    //   namespaceA: {},
    //   namespaceB: {},
    //   ...etc
    //  }
    // }
    allowMultiLoading: false, // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading

    parse(data) {
        const { translation } = JSON.parse(data);
        return translation;
    },

    // allow cross domain requests
    crossDomain: true,

    // allow credentials on cross domain requests
    withCredentials: false,

    // overrideMimeType sets request.overrideMimeType("application/json")
    overrideMimeType: false,

    queryStringParams: cacheBuster,
};

const config: InitOptions = {
    // do not load fallback
    fallbackLng: false,
    debug: process.env.NODE_ENV === "development",
    interpolation: {
        escapeValue: false,
        defaultVariables: {
            legalTosUrl: getThemeForRequest().legalTosUrl,
            legalContactUrl: getThemeForRequest().legalContactUrl,
            legalPrivacyUrl: getThemeForRequest().legalPrivacyUrl,
            legalFemaleTosUrl: "https://lc-legal.s3-ca-central-1.amazonaws.com/LuckyCrush_Additional_Terms_Applicable_to_Female_User_Accounts.pdf",
            brandName: getThemeForRequest().displayName,
            contactEmail: getThemeForRequest().emailContact,
        },
    },
    backend,
    supportedLngs,
    react: {
        // trigger a rerender when language is changed
        bindI18n: "languageChanged",
        // we're NOT using suspsense to detect when the translations have loaded
        useSuspense: false,
    },
    ns: "common",
    detection: {
        // order and from where user language should be detected
        order: ["querystring", "path", "localStorage", "cookie", "navigator"],

        // keys or params to lookup language from
        lookupQuerystring: "lang",
        lookupCookie: "lang",
        lookupLocalStorage: "lang",
        lookupFromPathIndex: 0,
        lookupFromSubdomainIndex: 0,

        // cache user language on
        caches: ["localStorage", "cookie"],
        excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

        // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
        cookieOptions: {
            path: "/",
            sameSite: "lax",
            domain: getThemeForRequest().domain,
        },
    },
    load: "currentOnly",
    saveMissing: false,

    // i only want keys to be shown as fallback for empty translations in dev mode
    parseMissingKeyHandler: (key: string) => {
        return key;
    },
};

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(I18NextHttpBackend)
    .init(config);

// reload currently selected language on a regular basis to maintain translations up to date
setInterval(() => {
    if (navigator.onLine) {
        i18n.reloadResources(i18n.language).catch(function () {});
    }
}, 60 * 1000);

export default i18n;
